import React, { Fragment, useContext } from 'react';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import {
  Breadcrumb as BreadcrumbType,
  useBreadcrumbsQueryQuery,
} from '../types/apolloTypes';
import { useHistory } from '../utils/routing';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import ChevronLeftLightIcon from './icons/ChevronLeftLightIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';
import TrailArrowIcon from './icons/TrailArrowIcon';
import Link from './Link';
import { MenuContext } from './MenuContextProvider';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';

export type BreadcrumbsBreadcrumb = {
  __typename?: 'Breadcrumb';
} & Pick<BreadcrumbType, 'name' | 'to' | 'image'>;

interface BreadcrumbBackButtonProps {
  onPress: () => void;
}

const BreadcrumbBackButton: React.FunctionComponent<BreadcrumbBackButtonProps> = ({
  onPress,
}) => {
  const { width } = useWindowDimensions();
  return (
    <PlatformTouchable
      style={[
        BreadcrumbStyles.backButton,
        width > screenSizes.medium && BreadcrumbStyles.backButtonDesktop,
      ]}
      onPress={onPress}
    >
      {width > screenSizes.medium ? (
        <ChevronLeftIcon fill={white} />
      ) : (
        <ChevronLeftLightIcon />
      )}
    </PlatformTouchable>
  );
};

/**
 * Breadcrumb Props
 *
 * @interface BreadcrumbProps
 */
interface BreadcrumbProps {
  breadcrumb: BreadcrumbsBreadcrumb;
  isFirst?: boolean;
  isLast?: boolean;
  isOnly?: boolean;
  index: number;
}

/**
 *  Breadcrumb
 *
 * @param {*} {
 *   breadcrumb,
 *   isFirst = false,
 *   isLast = false,
 * }
 * @returns
 */
const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({
  breadcrumb,
  isFirst = false,
  isLast = false,
  isOnly = false,
  index,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const isMobile = width <= screenSizes.medium;
  const mobileMargin = isMobile ? 15 : 6;
  const { updateAllBreadcrumbs, breadcrumbs } = useUpdateBreadcrumbs();

  const createBreadcrumbName = (originalName: string) => { 
    if (originalName === null || originalName === undefined || originalName.replaceAll(" ","").length === 0) {
      return "";
    }

    if (originalName.length < 20) {
      return originalName.toLowerCase();
    } 

    return originalName.toLowerCase().substring(0,20) + "...";
  };

  return (
    <Fragment>
      {!isFirst && <ChevronRightIcon fill="#ACACAC" />}
      <Link
        text={createBreadcrumbName(breadcrumb?.name)}
        to={breadcrumb?.to}
        onLinkPress={() =>
          updateAllBreadcrumbs(breadcrumbs.slice(0, index + 1))
        }
        style={[
          BreadcrumbStyles.breadcrumb,
          BreadcrumbStyles.breadcrumbDesktop,
          {
            color: isLast ? white : '#979D9F',
            backgroundColor:
              isLast && !isMobile
                ? '#234955'
                : isMobile
                ? '#303537'
                : themeColours.breadcrumb,
            marginLeft: (isFirst && isMobile) || isOnly ? 0 : mobileMargin,
            marginRight: isLast || isMobile ? 0 : mobileMargin,
          },
          isMobile && {
            color: '#E5F3F8',
          },
        ]}
      />
    </Fragment>
  );
};

/**
 *  BreadcrumbListProps
 *
 * @interface BreadcrumbListProps
 */
interface BreadcrumbListProps {
  breadcrumbs: BreadcrumbsBreadcrumb[];
}

/**
 *  Breadcrumb List
 *
 * @param {*} {
 *   breadcrumbs = [],
 * }
 * @returns
 */
const BreadcrumbList: React.FunctionComponent<BreadcrumbListProps> = ({
  breadcrumbs = [],
}) => {
  const history = useHistory();
  const { setOverlayTitle, setOverlayComponent } = React.useContext(
    MenuContext,
  );
  const { themeColours } = useContext(ThemeContext);
  return (
    <View
      style={[
        BreadcrumbStyles.breadcrumbList,
        { borderTopColor: themeColours.borderColor },
      ]}
    >
      {breadcrumbs.map((breadcrumb, index) => (
        <PlatformTouchable
          key={`${breadcrumb.to}${index}`}
          style={[
            BreadcrumbStyles.breadcrumbListItem,
            {
              backgroundColor: themeColours.header,
              borderBottomColor: themeColours.borderColor,
            },
          ]}
          onPress={() => {
            setOverlayTitle('');
            setOverlayComponent(null);
            history.push(breadcrumb.to);
          }}
        >
          <>
            {index !== 0 && <TrailArrowIcon />}
            <Text
              style={[
                BreadcrumbStyles.breadcrumbListText,
                {
                  color: themeColours.primaryFont,
                  marginLeft: index !== 0 ? 15 : 0,
                },
              ]}
            >
              {breadcrumb.name}
            </Text>
          </>
        </PlatformTouchable>
      ))}
    </View>
  );
};

/**
 * Breadcrumbs
 *
 * @returns
 */
const Breadcrumbs: React.FunctionComponent = () => {
  const { width = 1000 } = useWindowDimensions();
  const history = useHistory();

  const { breadcrumbs, updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  return (
    <View
      style={[
        BreadcrumbStyles.wrapper,
        width > screenSizes.medium && BreadcrumbStyles.wrapperDesktop,
        {
          justifyContent: width > screenSizes.medium ? 'flex-start' : 'center',
          flex: width > screenSizes.medium ? undefined : 1,
        },
      ]}
    >
      {breadcrumbs.length > 1 && (
        <BreadcrumbBackButton
          onPress={() => {
            if (breadcrumbs[breadcrumbs?.length - 2]) {
              history.push(breadcrumbs[breadcrumbs?.length - 2].to);
            }
            updateAllBreadcrumbs(breadcrumbs.slice(0, breadcrumbs?.length - 1));
          }}
        />
      )}
      {width > screenSizes.medium &&
        breadcrumbs.map((breadcrumb, index) => (
          <Breadcrumb
            key={`${breadcrumb.to}-${index}`}
            breadcrumb={breadcrumb}
            isFirst={index === 0}
            isLast={index === breadcrumbs?.length - 1}
            isOnly={breadcrumbs?.length === 1}
            index={index}
          />
        ))}
      {width <= screenSizes.medium && breadcrumbs?.length > 0 && (
        <>
          <Breadcrumb
            breadcrumb={breadcrumbs[breadcrumbs?.length - 1]}
            isLast={width > screenSizes.medium && true}
            isFirst={width <= screenSizes.medium}
            index={0}
          />
        </>
      )}
    </View>
  );
};
export default Breadcrumbs;

const BreadcrumbStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    paddingHorizontal: 15,
    // marginBottom: Platform.OS === 'ios' ? 10 : 0,
    flex: 1,
  },
  wrapperDesktop: {
    paddingHorizontal: 0,
  },
  btnPad: {
    padding: 10,
    margin: -10,
  },
  breadcrumb: {
    fontSize: 12,
    fontFamily: 'Quicksand-Medium',
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderRadius: 3,
  },
  breadcrumbDesktop: {
    fontSize: 11,
    fontFamily: 'Quicksand-Medium',
    marginLeft: 15,
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  moreButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 6,
    borderRadius: 2,
    marginHorizontal: 12,
  },
  breadcrumbList: {
    marginTop: 20,
    borderTopWidth: 1,
  },
  breadcrumbListItem: {
    borderBottomWidth: 1,
    paddingHorizontal: 25,
    paddingVertical: 15,
    flexDirection: 'row',
  },
  breadcrumbListText: {
    fontSize: 14,
    fontFamily: 'Quicksand-Medium',
  },
  breadcrumbMenu: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 5,
  },
  backButton: {
    paddingHorizontal: 15,
    position: 'absolute',
    left: 3,
  },
  backButtonDesktop: {
    position: 'relative',
    paddingHorizontal: 15,
    paddingVertical: 8,
    backgroundColor: '#234955',
    borderRadius: 2,
    marginRight: 6,
    left: 0,
  },
});
