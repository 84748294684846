import AsyncStorage from '@react-native-community/async-storage';
import jwt_decode from 'jwt-decode';
import * as Sentry from "@sentry/react";
import React, { createContext, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { subscriptionClient } from '../client/subscriptionClient';
import { persistor } from '../client/persisitor';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import {
  LoginWithPasswordMutation,
  useLoginWithPasswordMutation,
  useSetCurrentUserMutation,
} from '../types/apolloTypes';
import { useHistory, useLocation } from '../utils/routing';
import { useAuth0 } from "@auth0/auth0-react";
import env from '@beam-australia/react-env';


interface AuthContextProps {
  isAuthenticated: boolean;
  checkingAuth: boolean;
  loggingIn: boolean;
  setToken?: () => void;
  handleLogout?: (clearData?: boolean, redirect?: boolean) => void;
  loginWithRedirect?: () => void;
}

const defaultAuthContextProps: AuthContextProps = {
  isAuthenticated: false,
  checkingAuth: true,
  loggingIn: false,
};

export const AuthContext = createContext<AuthContextProps>(
  defaultAuthContextProps,
);

const clientId = env('AUTH0_CLIENT_ID');
const audience = env('AUTH0_AUDIENCE');

export let logoutGlobal: (
  clearData?: boolean,
  redirect?: boolean,
) => void | null = null;

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    defaultAuthContextProps.isAuthenticated,
  );
  const [checkingAuth, setCheckingAuth] = useState(
    defaultAuthContextProps.checkingAuth,
  );

  const client = useApolloClient();

  const [loginWithPassword, { loading }] = useLoginWithPasswordMutation({
    fetchPolicy: 'no-cache',
  });

  const [setCurrentUser] = useSetCurrentUserMutation();
  const history = useHistory();

  const checkAuthToken = async () => {
    setCheckingAuth(true);
    const token = await AsyncStorage.getItem('token');  
    if (!!token) setIsAuthenticated(true);
    setCheckingAuth(false);

  };

  useEffect(() => {
    checkAuthToken();
    logoutGlobal = handleLogout;

    return () => {
      logoutGlobal = null;
    };
  }, []);

  const location = useLocation();

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  const {
    loginWithRedirect,
    user,
    isLoading,
    getAccessTokenSilently,
    logout
  } = useAuth0();
  
  const setToken = async () => {  
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        clientId: `${env('AUTH0_CLIENT_ID')}`,
        audience: `${env('AUTH0_AUDIENCE')}`,
        scope: 'openid profile'
        }
    });
    const decodedToken = jwt_decode(accessToken);

    AsyncStorage.setItem('token', accessToken).then(() => {
        if (location.pathname === '/') {
          history.push('/?loggedIn=true');
          updateAllBreadcrumbs([
            {
              name: 'dashboard',
              to: '/',
            },
          ]);
        }
        window.location.reload(); 
     },
  );
};


  const handleLogout = (clearData, redirect) => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    AsyncStorage.removeItem('token').then(() => {
      setIsAuthenticated(false);
      if (redirect) {
        history.push('/');
      }

      subscriptionClient.close();

      if (clearData) {
        client.clearStore();
        persistor.purge();
      }
    });

  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loginWithRedirect,
        setToken,
        handleLogout,
        checkingAuth,
        loggingIn: loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
