import { ApolloClient } from 'apollo-client';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Platform, StyleSheet, Text } from 'react-native';
import json from '../../package.json';
import { white } from '../configs/colours';
import AuthProvider, { AuthContext } from './AuthProvider';
import LoadingView from './LoadingView';
import Routes from './Routes';
import ThemeProvider from './ThemeProvider';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import Constants from 'expo-constants';

interface Props {
  client: ApolloClient<any>;
}

const Apollo: React.FunctionComponent<Props> = ({ client }) => {
  const appVersion = json.version;
  const isDevStaging =
    Constants.appOwnership === 'expo' ||
    (Platform.OS === 'web' &&
      (location?.href?.indexOf('staging') > -1 ||
        location?.href?.indexOf('localhost') > -1 ||
          location?.href?.indexOf('localdev.transparently.legal') > -1));

  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <AuthProvider>
          <AuthContext.Consumer>
            {
              ({ isAuthenticated, checkingAuth }) => (
              <>
                { checkingAuth ? (<LoadingView />) : isAuthenticated ? (<Routes />) : (<UnauthenticatedRoutes />)}
                <Text
                  style={[
                    ApolloProviderStyle.appVersion,
                    !isDevStaging && { opacity: 0 },
                  ]} >
                  test: {checkingAuth}

                   version: {appVersion}
                  </Text>
              </>
            )}
          </AuthContext.Consumer>
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default Apollo;

const ApolloProviderStyle = StyleSheet.create({
  appVersion: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    opacity: 0.3,
    color: white,
    fontSize: 14,
    ...(Platform.OS === 'web' && { pointerEvents: 'none' }),
    fontFamily: 'Quicksand-Medium',
  },
});
