import { ApolloClient } from 'apollo-client';
import * as Font from 'expo-font';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import { useImmer } from 'use-immer';
import client from '../client/apolloClient';
import screenSizes from '../configs/screenSizes';
import { Router } from '../utils/routing';
import Alert, { AlertTypes } from './Alert';
import ApolloProvider from './ApolloProvider';
import ErrorBoundary from './ErrorBoundary';
import LoadingView from './LoadingView';
import env from '@beam-australia/react-env';
import * as Sentry from "@sentry/react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

Sentry.init({
  dsn: env('SENTRY_DSN'),
  environment: env('ENVIRONMENT'),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    exclude: [
      /^TouchableOpacity/,
      /^TouchableHighlight/,
      /^TouchableWithoutFeedback/,
    ],
  });
}

const startServiceWorker = async (
  createUIPrompt: (props: {
    onAccept: () => void;
    onReject: () => void;
  }) => { dismiss: () => void },
) => {
  if ('serviceWorker' in navigator) {
    const { Workbox, messageSW } = await import('workbox-window');

    const wb = new Workbox('/service-worker.js');

    let registration;

    wb.addEventListener('activated', (event) => {
      if (!event.isUpdate) {
        console.log('Service worker activated!');
      }
      if (event.isUpdate) {
        console.log('Service worker is updating');
      }
    });

    const showSkipWaitingPrompt = (event) => {
      const prompt = createUIPrompt({
        onAccept: async () => {
          wb.addEventListener('controlling', (event) => {
            window.location.reload();
          });

          if (registration && registration.waiting) {
            messageSW(registration.waiting, { type: 'SKIP_WAITING' });
            prompt?.dismiss();
          }
        },

        onReject: () => {
          prompt?.dismiss();
        },
      });
    };

    wb.addEventListener('waiting', showSkipWaitingPrompt);
    // @ts-ignore
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

    wb.addEventListener('installing', (event) => {
      console.log(`Service worker installing`);
    });

    wb.addEventListener('installed', (event) => {
      console.log(`Service worker installed`);
    });

    wb.register().then((r) => (registration = r));
  }
};

const App = () => {

  const [apolloClient, setApolloClient] = useState<
    ApolloClient<any> | undefined
  >();

  const { width } = useWindowDimensions();

  const [promptState, setPromptState] = useImmer({
    onAccept: () => console.log('accepted'),
    onReject: () => null,
    show: false,
  });

  const createUIPrompt = useCallback(
    (props: { onAccept: () => void; onReject: () => void }) => {
      setPromptState((f) => {
        f.onAccept = props.onAccept;
        f.onReject = props.onReject;
        f.show = true;
      });

      return {
        dismiss: () => {
          setPromptState((f) => {
            f.show = false;
          });
        },
      };
    },
    [],
  );

  useEffect(() => {
    if (Platform.OS !== 'web') {
      Font.loadAsync({
        'Quicksand-Regular': require('../../assets/fonts/Quicksand-Regular.ttf'),
        'Quicksand-Medium': require('../../assets/fonts/Quicksand-Medium.ttf'),
        'Quicksand-Bold': require('../../assets/fonts/Quicksand-Bold.ttf'),
        'Quicksand-Light': require('../../assets/fonts/Quicksand-Light.ttf'),
      }).then(() => {
        setFontsLoaded(true);
      });
    }

    if (
      Platform.OS === 'web' &&
      !!env('ENVIRONMENT') &&
      env('ENVIRONMENT') !== 'development'
    ) {
      startServiceWorker(createUIPrompt);
    }

    const waitClient = async () => {
      const cli = await client();
      setApolloClient(cli);
    };
    waitClient();
  }, []);

  return   apolloClient ? (
    <ErrorBoundary>
      <Router>
        <ApolloProvider client={apolloClient} />
      </Router>
      {promptState.show && (
        <View
          style={[
            AppStyle.alertsWrap,
            {
              left: width > screenSizes.medium ? width / 2 - 250 : 10,
              width: width > screenSizes.medium ? 500 : width - 20,
            },
          ]}
        >
          <Alert
            removeAlert={() =>
              setPromptState((f) => {
                f.show = false;
              })
            }
            hash={123}
            duration={0}
            onClose={promptState.onReject}
            onPress={promptState.onAccept}
            type={AlertTypes.INFO}
            text="a new version of transparently is available, click here to reload"
          />
        </View>
      )}
    </ErrorBoundary>
  ) : (
    <View style={[AppStyle.loader]}>
      <LoadingView />
    </View>
  );
};
// export default App;


const Root = () => (
  <Auth0Provider
    domain={env('AUTH0_DOMAIN')}
    clientId={env('AUTH0_CLIENT_ID')}
    useRefreshTokens
    authorizationParams={{  
      audience: env('AUTH0_AUDIENCE'),
      redirect_uri: window.location.origin 
    }}
    scope="openid profile offline_access"
    cacheLocation='localstorage'
  >
    <App />
  </Auth0Provider>
);

export default Root;
const AppStyle = StyleSheet.create({
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderText: {
    fontSize: 16,
  },
  alertsWrap: {
    position: 'absolute',
    width: 500,
    top: 120,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
